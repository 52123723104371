<template>
  <div class="caseDetail">
    <PageTitle ttl-en="CASE" ttl-jp="_想定課題" />
    <div class="inner">
      <p class="caseDetail__mv js-scroll">
        <img src="/genbashiko/img/case/case08/mv.jpg" alt="" />
      </p>
      <section class="caseDetail__outline">
        <h1 class="js-scroll">
          塗装後検査における適切な照明ピッチが
          <br />分からない
        </h1>
        <div class="caseDetail__outline__txt js-scroll">
          塗装工程では照明を等間隔に並べて設置します。この照明間隔(=照明ピッチ)は検査を行う上でも重要な要素の一つです。<br />ゼブラ検査では、車輛に反射する光の連続性を使って不良を見つけやすくしていますが、これが適切なピッチより広いと不良は見逃しやすく、狭いと目が疲れやすくなってしまいます。<br />
          <a href="/genbashiko/column/column07/">コラム「外観検査に最適な照度とは？～検査現場の作業環境を改善～」</a
          >で紹介しているように、Luciは灯具間300㎜ピッチを推奨していますが、皆さまの中には「より多くのパターンを検証したい。」「車輌サイズが変わった際に工程も変化させたい。」といった考えの方もいらっしゃるのではないでしょうか。
        </div>
        <div class="caseDetail__outline__cate js-scroll">
          <p>担当領域:</p>
          <ul>
            <li>
              <router-link
                class="category"
                :to="{ name: 'Case', query: { category: '新たに検査現場を構築したい' }}"
              >
                新たに検査現場を構築したい
              </router-link>
            </li>
            <li>
              <router-link class="category" :to="{ name: 'Case', query: { category: '既存の検査場を改修したい' }}">
                既存の検査場を改修したい
              </router-link>
            </li>
            <li>
              <router-link class="category" :to="{ name: 'Case', query: { category: '現状の課題を確認したい' }}">
                現状の課題を確認したい
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=現場調査">
                現場調査
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=施策策定">
                施策策定
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=コンサルティング">
                コンサルティング
              </router-link>
            </li>
            <li>
              <router-link class="category" to="/case?category=製品開発">
                製品開発
              </router-link>
            </li>
            <li>
              <router-link class="category" :to="{ name: 'Case', query: { category: '塗装完成車工程' }}">
                塗装完成車工程
              </router-link>
            </li>
          </ul>
        </div>
      </section>
      <section class="caseDetail__env">
        <h3 class="caseDetail__env__ttl js-scroll">想定される環境</h3>
        <ul class="js-scroll">
          <li>蛍光灯のライン照明が上部に設置された塗装検査ライン</li>
        </ul>
      </section>
      <section class="caseDetail__ex">
        <h3 class="caseDetail__ex__ttl js-scroll">想定課題と具体的な改善施策例</h3>
        <div class="caseDetail__ex__problem js-scroll">
          <div class="caseDetail__ex__problem__p">
            <p class="ttl">課題1</p>
            <p class="txt">導入後、照明ピッチの切り替えが出来ない</p>
          </div>
          <div class="caseDetail__ex__problem__a">
            <span class="ttl">施策1</span>
            <p class="txt">ライティングレールを用いた照明を導入</p>
          </div>
          <div class="caseDetail__ex__problem__img">
            <p>
              <img src="/genbashiko/img/case/case08/image02.png" alt="" />
            </p>
          </div>
          <p class="caseDetail__ex__problem__cap">
            一般的な2点留めではなく、レール通電と1点取付が可能な「ゼブライン」を導入。
            <br />施工後もピッチ調整が可能となります。
          </p>
          <div class="caseDetail__ex__movie single js-scroll">
            <div class="caseDetail__ex__movie__block">
              <p class="caseDetail__ex__movie__ttl">
                ▼商品ムービー
              </p>
              <div class="caseDetail__ex__movie__iframeWrap">
                <iframe
                  src="https://www.youtube.com/embed/VABzGD1rC1w?si=w8zryzjRdpRHzkgQ"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <p class="caseDetail__ex__download js-scroll">
            <a
              href="/genbashiko/upload/download_pdf/PA-LZL.pdf"
              target="_blank"
              >課題に関する資料ダウンロード</a
            >
          </p>
        </div>
        <div class="caseDetail__ex__problem js-scroll">
          <div class="caseDetail__ex__problem__p">
            <p class="ttl">課題2</p>
            <p class="txt">検討したいが工場のLED化は完了している</p>
          </div>
          <div class="caseDetail__ex__problem__a">
            <span class="ttl">施策2</span>
            <p class="txt">試験、教育用の工程からミニマム導入をご提案</p>
          </div>
          <div class="caseDetail__ex__problem__img">
            <p>
              <img src="/genbashiko/img/case/case08/image01.jpg" alt="" />
            </p>
          </div>
          <p class="caseDetail__ex__problem__cap">
            車輌1台分など、最小限な規模へのご提案も可能です。
            <br />また、低予算で導入いただけるキャスター付きの簡易照明も揃えております。
          </p>
        </div>
        <p class="caseDetail__ex__download js-scroll">
          <a
            href="/genbashiko/upload/download_pdf/PA-LZL.pdf"
            target="_blank"
            >課題に関する資料ダウンロード</a
          >
        </p>
      </section>
    </div>
  </div>
</template>
<script>
import PageTitle from "@/components/PageTitle.vue";

export default {
  components: {
    PageTitle,
  },
  head: {
    title: {
      inner: "Case08",
    },
    meta: [
      { property: "og:title", content: "Case08｜GENBA SHIKO" },
      {
        name: "description",
        content:
          "車輌サイズが変わった際に工程も変化させたいなど様々なパターンに合わせたい方、適切な照明間隔が分からない方へ、塗装検査ラインの導入で施工性向上をサポートします。",
      },
      {
        property: "og:description",
        content:
          "車輌サイズが変わった際に工程も変化させたいなど様々なパターンに合わせたい方、適切な照明間隔が分からない方へ、塗装検査ラインの導入で施工性向上をサポートします。",
      },
      {
        property: "og:url",
        content: "/genbashiko/case/case08",
      },
      { property: "og:type", content: "article" },
    ],
  },
};
</script>
